import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";

const educationData = [
  {
    id: 1,
    title: "Senior Developer",
    place: "Officeworks",
    years: "2021 - Present",
    content:
      "Development, enhancement, and support of EPOS software used in all the Officeworks stores.",
  },
  {
    id: 2,
    title: "Solution Architect",
    place: "BMW Australia",
    years: "2015 - 2020",
    content:
      "Modernized cloud-based application to improve productivity by reducing deplyment time and efforts",
  },
  {
    id: 3,
    title: "Senior Consultant",
    place: "pwc",
    years: "2015 - 2015",
    content:
      "Developed mobile banking solution using JavaScript, AngularJS and Mobile First Platform.",
  },
];

const experienceData = [
  {
    id: 1,
    title: "Solution Architect",
    place: "Sumeru Software",
    years: "2007 - 2014",
    content:
      "Managed delivery of 7 teams of total 25+ people. Designed, Developed and managed projects.",
  },
  {
    id: 2,
    title: "Associate",
    place: "Perot Systems",
    years: "2005 - 2007",
    content:
      "Lorem ipsum dolor sit amet quo ei simul congue exerci ad nec admodum perfecto.",
  },
  {
    id: 3,
    title: "Freelancer",
    place:"WFH",
    years: "2008 - Present",
    content:
      "Developed 2D board game using Unity3d. Built a Chatbots using Google Dialog Flow.",
  },
];

function Experiences() {
  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title="Experience" />
        <div className="row">
          <div className="col-md-6">
            <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
              {educationData.map((education) => (
                <Timeline key={education.id} education={education} />
              ))}
              <span className="line"></span>
            </div>
          </div>

          <div className="col-md-6">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {experienceData.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}
              <span className="line"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experiences;
